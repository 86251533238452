var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResizeHandler,
          expression: "onResizeHandler",
        },
      ],
      staticClass: "pa-1 product-view",
      style: "height: " + _vm.windowSize.y + "px; position: 'relative'",
      attrs: { flat: "" },
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 70,
                  width: 7,
                  color: "primary",
                  indeterminate: "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-form",
            {
              ref: "receiveForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0 font-weight-bold",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("message.common.orderno")) +
                          ": " +
                          _vm._s(_vm.orderModel.number)
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-1 px-1", attrs: { cols: "12" } },
                    [
                      _c("barcode-scanner", {
                        attrs: {
                          search: _vm.scanProduct,
                          scannerPayload: _vm.scannerPayload,
                          loading: _vm.productLoading,
                        },
                        model: {
                          value: _vm.scanProduct,
                          callback: function ($$v) {
                            _vm.scanProduct = $$v
                          },
                          expression: "scanProduct",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.productFound
                ? _c("div", { staticClass: "mt-1" }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("message.order.products")) +
                          ": " +
                          _vm._s(_vm.productInfo.productname || "")
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "v-row",
                [
                  _vm.productFound && _vm.productInfo.isbatch
                    ? _c(
                        "v-col",
                        { staticClass: "pa-0 py-1", attrs: { cols: "6" } },
                        [
                          [
                            _c("v-text-field", {
                              ref: "batchRef",
                              attrs: {
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                flat: "",
                                autocomplete: "off",
                                rules: _vm.$_requiredValidation,
                                label: _vm.$t("message.product.batch"),
                                loading: _vm.loadingBatch,
                              },
                              model: {
                                value: _vm.selectBatch,
                                callback: function ($$v) {
                                  _vm.selectBatch = $$v
                                },
                                expression: "selectBatch",
                              },
                            }),
                          ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.productFound
                    ? _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-2 text-center pa-0",
                                  attrs: { cols: "3" },
                                  on: { click: _vm.decreaseQty },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "info",
                                        fab: "",
                                        "x-small": "",
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-minus")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 mt-1",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    ref: "qtyRef",
                                    staticClass: "text-right",
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      type: "number",
                                      autocomplete: "off",
                                      rules: _vm.$_qtyValidation,
                                      label: _vm.$t("message.product.qty"),
                                    },
                                    model: {
                                      value: _vm.pickQty,
                                      callback: function ($$v) {
                                        _vm.pickQty = $$v
                                      },
                                      expression: "pickQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-2 text-center pa-0",
                                  attrs: { cols: "3" },
                                  on: { click: _vm.increaseQty },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "info",
                                        fab: "",
                                        "x-small": "",
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.productFound ? _c("v-row") : _vm._e(),
              _vm.productFound
                ? _c(
                    "v-col",
                    { staticClass: "pa-0 pr-1 my-1", attrs: { cols: "12" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.listOfWarehouses,
                          "item-text": "shortname",
                          "item-value": "id",
                          "hide-details": "",
                          outlined: "",
                          label: _vm.$t("message.layout.warehouse"),
                          dense: "",
                          rules: _vm.$_requiredValidation,
                        },
                        on: { change: _vm.getLocationsByWarehouse },
                        model: {
                          value: _vm.selectedWarehouse,
                          callback: function ($$v) {
                            _vm.selectedWarehouse = $$v
                          },
                          expression: "selectedWarehouse",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productFound && _vm.locationsSet
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0 px-0", attrs: { cols: "5" } },
                        [
                          _c("v-autocomplete", {
                            ref: "scanLocation",
                            attrs: {
                              items: _vm.listOfLocations,
                              "item-text": "name",
                              "item-value": "name",
                              "hide-details": "",
                              outlined: "",
                              loading: _vm.loadingLocation,
                              label: _vm.$t("message.product.scanLoc"),
                              flat: "",
                              autocomplete: "off",
                              dense: "",
                              rules: _vm.$_requiredValidation,
                            },
                            on: { change: _vm.checkIfLocationValid },
                            model: {
                              value: _vm.selectedLocation,
                              callback: function ($$v) {
                                _vm.selectedLocation = $$v
                              },
                              expression: "selectedLocation",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0 pb-0", attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            staticClass: "text-right",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              type: "text",
                              autocomplete: "off",
                              label: _vm.$t("message.mixed.qrCode"),
                            },
                            model: {
                              value: _vm.qr_code,
                              callback: function ($$v) {
                                _vm.qr_code = $$v
                              },
                              expression: "qr_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0 pb-0", attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            staticClass: "text-right",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              type: "number",
                              autocomplete: "off",
                              label: _vm.$t("message.mixed.qrQty"),
                            },
                            model: {
                              value: _vm.qr_code_qty,
                              callback: function ($$v) {
                                _vm.qr_code_qty = $$v
                              },
                              expression: "qr_code_qty",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0 py-1", attrs: { cols: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", dark: "", small: "" },
                              on: { click: _vm.completeOrderHandler },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("message.order.receive")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "highlight pa-0 pb-n2 px-1" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-2 font-weight-bold",
                  attrs: { cols: "4" },
                },
                [_vm._v(_vm._s(_vm.$t("message.whsDashboard.product")) + ":")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pr-2 text-center font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.ccsheet.unit")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.ordered")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.received")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.rest")))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "pa-0" })],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.listOfProducts, function (product, index) {
            return _c(
              "v-row",
              {
                key: index,
                staticClass: "pb-3",
                on: {
                  click: function ($event) {
                    return _vm.scanProductByClick(product.productnumber)
                  },
                },
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pl-2 text-truncate",
                    class: { "pb-1": index === _vm.listOfProducts.length - 1 },
                    attrs: { cols: "4", title: product.productnumber },
                  },
                  [
                    _vm._v(" " + _vm._s(product.productnumber) + " "),
                    _c("br"),
                    _c("span", [_vm._v(_vm._s(product.productname))]),
                  ]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 text-center text-truncate",
                    attrs: { cols: "2", title: product.unitname },
                  },
                  [_vm._v(_vm._s(product.unitname))]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 text-right text-truncate",
                    attrs: { cols: "2" },
                  },
                  [_vm._v(_vm._s(_vm._f("absoluteNumber")(product.qty)))]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pr-3 text-right text-truncate",
                    attrs: { cols: "2" },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm._f("absoluteNumber")(product.receivedqty_ref))
                    ),
                  ]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pr-3 text-right text-truncate",
                    attrs: { cols: "2" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("absoluteNumber")(product.rest_qty)) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "v-row",
        { staticClass: "pt-12" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-16",
                  attrs: {
                    small: "",
                    color: "primary",
                    dark: "",
                    absolute: "",
                    bottom: "",
                    left: "",
                    tabindex: "-1",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/supplier_orders/" + _vm.orderId)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-arrow-left-bold"),
                  ]),
                  _vm._v(_vm._s(_vm.$t("message.login.back")) + " "),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mt-16",
                  attrs: {
                    small: "",
                    color: "primary",
                    dark: "",
                    absolute: "",
                    bottom: "",
                    right: "",
                    tabindex: "-1",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/supplier_orders/" + _vm.orderId)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("message.order.complete")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "" },
          model: {
            value: _vm.completeDialog,
            callback: function ($$v) {
              _vm.completeDialog = $$v
            },
            expression: "completeDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "pa-2" },
                [
                  _vm._v(" " + _vm._s(_vm.$t("message.ccsheet.info")) + " "),
                  _c("v-spacer"),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-2" }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("message.order.receiveWarning", {
                        qty: _vm.$formatter.absoluteNumber(_vm.pickQty),
                        product:
                          (_vm.productInfo.productnumber || "") +
                          " - " +
                          (this.productInfo.productname || ""),
                      })
                    )
                  ),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "info",
                        small: "",
                        loading: _vm.saveLoading,
                      },
                      on: { click: _vm.receiveQtyOnOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.completeDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "400" },
          model: {
            value: _vm.moreQtyObj.dialog,
            callback: function ($$v) {
              _vm.$set(_vm.moreQtyObj, "dialog", $$v)
            },
            expression: "moreQtyObj.dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline grey lighten-2",
                  attrs: { "primary-title": "" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("message.common.warning")) + " ")]
              ),
              _c("v-card-text", { staticClass: "pa-5" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("message.order.orderedQtyHigh"))),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success" },
                      on: { click: _vm.moreQtyObj.onSuccess },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.yes")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.moreQtyObj.onCancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }